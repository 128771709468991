export { default as common } from "./common";
import linehaulReference from "./cardMiniLinehaulReference";
import linehaulType from "./cardMiniLinehaulType";
import linehaulProvider from "./cardMiniLinehaulProvider";
import linehaulStatus from "./cardMiniLinehaulStatus";
import linehaulLogistics from "./cardLinehaulLogistics";
import linehaulOrders from "./cardLinehaulOrders";
import linehaulLogs from "./cardLinehaulLogs";
import linehaulDates from "./cardLinehaulDates";
import linehaulCost from "./cardLinehaulCost";
import linehaulPallets from "./cardLinehaulPallets";
import cardAdditionalInfo from "./cardAdditionalInfo";

export const card = {
  linehaulReference,
  linehaulType,
  linehaulProvider,
  linehaulStatus,
  linehaulLogistics,
  linehaulOrders,
  linehaulLogs,
  linehaulDates,
  linehaulCost,
  linehaulPallets,
  cardAdditionalInfo,
};
